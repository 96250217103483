import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize () {
    document.addEventListener('click', (e) => {
      if (!this.element.contains(e.target)) {
        this.element.removeAttribute('open');
      }
    })   
  }
}