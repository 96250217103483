import jQuery from 'jquery'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'productPriceId',
    'kindOfPrice', 'priceFee', 'priceMargin', 'price',
    'shopPrice', 'profit', 'revenue',
    'profitMessage', 'validityMessage'
  ]

  connect() {
    this.setTargetsFieldsDisabled();
  }

  getCalculatedPrice() {
    this.getValues();
    this.setTargetsFieldsDisabled();
  }

  setTargetsFieldsDisabled() {
    switch(this.kindOfPriceTarget.value) {
      case 'dynamic':
        this.showTarget(this.priceFeeTarget);
        this.enableTarget(this.priceFeeTarget);

        this.showTarget(this.priceMarginTarget);
        this.enableTarget(this.priceMarginTarget);

        this.hideField(this.priceTarget);

        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
      case 'temp_not_dynamic':
        this.showTarget(this.priceFeeTarget);
        this.enableTarget(this.priceFeeTarget);

        this.showTarget(this.priceMarginTarget);
        this.enableTarget(this.priceMarginTarget);

        this.showTarget(this.priceTarget);
        this.enableTarget(this.priceTarget);
        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
      case 'temp_not_from_brand':
        this.showTarget(this.priceFeeTarget);
        this.disableTarget(this.priceFeeTarget);
        this.showTarget(this.priceMarginTarget);
        this.disableTarget(this.priceMarginTarget);

        this.showTarget(this.priceTarget);
        this.enableTarget(this.priceTarget);
        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
      case 'from_brand':
        this.showTarget(this.priceFeeTarget);
        this.disableTarget(this.priceFeeTarget);
        this.showTarget(this.priceMarginTarget);
        this.disableTarget(this.priceMarginTarget);

        this.hideField(this.priceTarget);
        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
      case 'fixed':
        this.hideField(this.priceFeeTarget);

        this.hideField(this.priceMarginTarget);

        this.showTarget(this.priceTarget);
        this.enableTarget(this.priceTarget);
        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
      case 'from_marketplace':
        this.showTarget(this.priceFeeTarget);
        this.disableTarget(this.priceFeeTarget);
        this.showTarget(this.priceMarginTarget);
        this.disableTarget(this.priceMarginTarget);

        this.hideField(this.priceTarget);
        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
      case 'temp_not_marketplace':
        this.showTarget(this.priceFeeTarget);
        this.disableTarget(this.priceFeeTarget);
        this.showTarget(this.priceMarginTarget);
        this.disableTarget(this.priceMarginTarget);

        this.showTarget(this.priceTarget);
        this.enableTarget(this.priceTarget);
        this.showTarget(this.shopPriceTarget);
        this.disableTarget(this.shopPriceTarget);
        break;
    }
  }

  getValues() {
    var uri = '/offers/' + this.productPriceIdTarget.value + '/edit.json';

    jQuery.ajax({
      url: uri,
      method: 'GET',
      data: {
        offer: {
          kind_of_price: this.kindOfPriceTarget.value,
          price_fee: this.priceFeeTarget.value.replace(',', '.').replace('€ ', ''),
          price_margin: this.priceMarginTarget.value,
          price: this.priceTarget.value.replace(',', '.').replace('€ ', '')
        }
      },
      success: response => {
        this.updateTargets(response);
      }
    });
  }

  updateTargets(data) {
    this.shopPriceTarget.value = data.shop_price;
    this.priceTarget.value = data.price;
    this.revenueTarget.value = data.revenue;
    this.profitTarget.value = data.profit;

    if (data.kind_of_price == 'from_marketplace') {
      this.priceFeeTarget.value = data.marketplace.price_fee;
      this.priceMarginTarget.value = data.marketplace.price_margin;
    } else {
      this.priceFeeTarget.value = data.price_fee;
      this.priceMarginTarget.value = data.price_margin;
    }

    if (data.profitable) {
      this.profitMessageTarget.style.display = 'none';
    } else {
      this.profitMessageTarget.style.display = 'grid';
    }

    if (data.valid) {
      this.validityMessageTarget.style.display = 'none';
    } else {
      this.validityMessageTarget.style.display = 'grid';
    }
  }

  hideField(element) {
    element.parentNode.parentNode.style.display = 'none';
    this.disableTarget(element);
  }

  showTarget(element) {
    element.parentNode.parentNode.style.display = 'grid';
  }

  enableTarget(element) {
    element.removeAttribute('disabled');
  }

  disableTarget(element) {
    element.setAttribute('disabled', '');
  }
}
